import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    postal_code
    country_code
    subdivision_code
    locality
    line_1
    line_2
  }
`;

// if you change this shape, ensure you update the type def in types/note.ts
export const NoteFragment = gql`
  fragment NoteFragment on Note {
    author {
      profile {
        photo {
          url
        }
        first_name
        last_name
        email
      }
    }
    date
    id
    text
  }
`;

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    id
    first_name
    last_name
    email
    phone
    state_id
    activities
    birthdate
    volunteer_distance_meters
    adoption_application_id
    foster_application_id
    surrender_application_id
    volunteer_application_id
    tags
    notes {
      ...NoteFragment
    }
    photo {
      id
      url
    }
    address {
      ...AddressFragment
    }
    documents {
      description
      id
      tags
      date
      url
    }
  }
  ${AddressFragment}
  ${NoteFragment}
`;

export const OrgFragment = gql`
  fragment OrgFragment on Organization {
    id
    name
    email
    nonprofit_501_c_3
    created_at
    phone
    website
    type
    about
    mission_statement
    logo {
      id
      url
    }
    settings {
      stripe_customer_id
      stripe_subscription_id
    }
    primary_address {
      ...AddressFragment
    }
  }
  ${AddressFragment}
`;

export const MedicalEvent = gql`
  fragment MedicalEvent on Pet_MedicalEvent {
    id
    cost {
      currency
      amount
    }
    clinic
    date
  }
`;

export const NoteSubMedical = gql`
  fragment note on Note {
    id
    text
  }
`;

export const PetFragment = gql`
  fragment PetFragment on Organization_Pet {
    internal_id
    status
    created_at
    adoption_fee {
      currency
      amount
    }
    location {
      boarding
      foster {
        id
        first_name
        last_name
      }
      shelter_unit
    }
    pet {
      altered
      mixed
      breed_dog
      breed_cat
      breed_small_animal
      breed_rabbit
      breed_guinea_pig
      color
      description
      special_needs
      youtube_video_url
      species
      license
      medical_events {
        id
        cost {
          currency
          amount
        }
        clinic
        vet
        reminders {
          id
          title
          message
          notification_preferences
          recipients {
            id
          }
          schedules {
            id
            date
            end_date
            recurrence_pattern
          }
        }
        records {
          id
          __typename
          line_items {
            id
            note {
              id
              text
            }
            ... on Pet_MedicalEvent_Record_Diagnosis_LineItem_DiagnosisGeneral {
              name
            }
            ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FivFelvTest {
              felv_positive
              fiv_positive
            }
            ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FecalResult {
              positive
            }
            ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Heartworm {
              positive
            }
            ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_FleaTick {
              flea_positive
              tick_positive
            }
            ... on Pet_MedicalEvent_Record_MedicationTreatment_LineItem_Medication {
              name
              start_date
              frequency
              dose
              end_date
            }
            ... on Pet_MedicalEvent_Record_WellnessVaccines_LineItem_Vaccine {
              name
              manufacturer
              serial_number
              expiration_date
            }
            ... on Pet_MedicalEvent_Record_MedicationTreatment_LineItem_Medication {
              dose
              end_date
              frequency
              name
              start_date
            }
          }
        }
        date
      }
      notes {
        ...NoteFragment
      }
      documents {
        description
        id
        tags
        date
        url
      }
      images {
        id
        url
      }
      father {
        id
        name
      }
      mother {
        id
        name
      }
      siblings {
        id
        name
      }
      diet {
        brand
        frequency
        instructions
        quantity
        type
      }
      gender
      id
      microchip {
        id
        brand
      }
      status
      name
      rabies_tag
      weight {
        unit
        value
      }
      good_with_cats
      good_with_dogs
      good_with_children
      declawed
      needs_experienced_adopter
      housetrained
      has_bite_history
      distinguishing_marks
      estimated_birth_date
      events {
        id
        date
        event_type
        ... on Pet_Event_Intake {
          intake_type
          condition
          from {
            ... on Person {
              first_name
              last_name
              id
            }
            ... on ExternalOrganization {
              name
              id
            }
          }
        }
        ... on Pet_Event_Transfer {
          to {
            ... on ExternalOrganization {
              name
              id
            }
            ... on Organization {
              name
              id
            }
          }
        }
        ... on Pet_Event_Euthanized {
          euthanized_type
        }
        ... on Pet_Event_Adoption {
          to {
            first_name
            id
          }
        }
        ... on Pet_Event_DiedInCare {
          date
        }
        ... on Pet_Event_Return {
          date
        }
      }
    }
  }
  ${NoteFragment}
`;

export const PetIndexFragment = gql`
  fragment PetIndexFragment on Organization_Pet {
    id
    internal_id
    status
    created_at
    adoption_fee {
      amount
      currency
    }
    location {
      boarding
      foster {
        id
        first_name
        last_name
      }
      shelter_unit
    }
    pet {
      altered
      mixed
      breed_dog
      breed_cat
      breed_small_animal
      breed_rabbit
      breed_guinea_pig
      color
      description
      special_needs
      youtube_video_url
      species
      license
      images {
        id
        url
      }
      gender
      id
      microchip {
        id
        brand
      }
      father {
        name
      }
      mother {
        name
      }
      siblings {
        id
        name
      }
      status
      name
      rabies_tag
      weight_lbs
      weight {
        unit
        value
      }
      good_with_cats
      good_with_dogs
      good_with_children
      declawed
      needs_experienced_adopter
      housetrained
      has_bite_history
      distinguishing_marks
      estimated_birth_date
      events {
        id
        date
        event_type
        ... on Pet_Event_Intake {
          intake_type
          condition
          from {
            ... on Person {
              first_name
              last_name
              id
            }
            ... on ExternalOrganization {
              name
              id
            }
          }
        }
        ... on Pet_Event_Transfer {
          to {
            ... on ExternalOrganization {
              name
              id
            }
            ... on Organization {
              name
              id
            }
          }
        }
        ... on Pet_Event_Euthanized {
          euthanized_type
        }
        ... on Pet_Event_Adoption {
          to {
            first_name
            last_name
            id
          }
        }
        ... on Pet_Event_DiedInCare {
          date
        }
        ... on Pet_Event_Return {
          date
        }
      }
    }
  }
`;
